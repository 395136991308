<template>
    <div>
        <template v-if="pending">
            <div class="flex">
                <div v-for="achievementLoader in skeletonCount" :key="achievementLoader" class="rounded-full achievement m-8">
                    <skeleton width="32" height="32" rounded-full/>
                </div>
            </div>
        </template>
        <div v-else-if="achievements && achievements.length" class="flex flex-wrap items-center">
            <div v-for="(achievement, key) in achievements" :key="key" class="m-8 overflow-hidden" :class="{'rounded-full': !['admin', 'label'].includes(achievement.label)}">
                <tooltip v-if="achievement.label === 'private_profile'" :text="achievement.title" top with-touch>
                    <span class="border rounded-full w-32 h-32 flex items-center justify-center">
                        <icon :icon="icons.anonymous" small/>
                    </span>
                </tooltip>
                <tooltip v-else-if="achievement.image" top :text="achievement.title" with-touch>
                    <cdn-image :src="achievement.image.path" :size="{width: 32, ratio: achievement.image.ratio}" :alt="achievement.title"/>
                </tooltip>
            </div>
        </div>
        <missing-info v-else-if="!noMissing" :icon="icons.achievement" no-spacing :text="$t('missing.achievements')"/>
    </div>

</template>

<script setup lang="ts">
import {mdiIncognito, mdiTrophyOutline} from '@mdi/js';

const {$api} = useNuxtApp();

const props = withDefaults(defineProps<{
	// The model for which to load the achievements
	modelName: string,
	modelKey: string | number,

	// Whether the achievements shall be loaded for the authenticated user
	auth?: boolean,

	// Whether the achievements shall be loaded on server side
	ssr?: boolean,

	// Whether to ignore a missing alert when no achievements exist
	noMissing?: boolean,

	skeletonCount?: number,
}>(), {
	skeletonCount: 2
});

const icons = {
	anonymous: mdiIncognito,
	achievement: mdiTrophyOutline
};

const {reactiveData: achievements, pending} = await useApiFetch(`/v1/${props.modelName === 'user' ? 'athlete' : props.modelName}s/${props.modelKey}/achievements`, {
	guest: !props.auth,
	auth: props.auth,
	server: props.ssr
});
</script>